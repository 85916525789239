import React, { useRef, useEffect } from 'react'
import ScrollReveal from 'scrollreveal'
import LogoCloud from './images/logonegro_crm.svg'

// import { NewsletterForm } from './newsletter-form'

export function Hero({ title, content, illustration, content2, email }) {
  const scrollRevealRef = useRef([])

  useEffect(() => {
    if (scrollRevealRef.current) {
      scrollRevealRef.current.map((ref, index) =>
        ScrollReveal().reveal(scrollRevealRef.current[index], {
          duration: 1000,
          distance: '40px',
          easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
          origin: 'top',
          interval: 150,
        })
      )
    }

    return () => ScrollReveal().destroy()
  }, [])
/*
  function onNewsletterSubmit(values) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ values })
      }, 1000)
    })
  }
  */

  return (
    <section className="text-center lg:w-full lg:py-20 lg:text-left">
      <div className="hero mx-auto w-full max-w-6xl px-6">
        <div className="hero-inner relative lg:flex">
          <div
            className="hero-copy bg-white pt-10 pb-16 lg:pt-16 lg:pr-20"
            style={{ minWidth: '600px' }}
          >
            <div className="mx-auto w-full max-w-3xl" >
              <img className="mt-0 mb-4 text-4xl font-bold md:text-5xl " src={LogoCloud} alt="logombcloud" style={{width: '300px'}} ref={(el) => (scrollRevealRef.current[0] = el)}/>
              
              <p
              style={{fontFamily: 'Akrobat Light', lineHeight: '22px'}}
                ref={(el) => (scrollRevealRef.current[1] = el)}
                className="prose prose-xl px-16 text-gray-500 md:px-0"
              >
                {content} <br/> {content2} <br/> <a style={{textDecoration: 'none'}}  href="mailto:inversiones@grupomexbridge.com"><b>{email}</b></a>
              </p>
              

            </div>
           
{/**
 *  <button style={{backgroundColor: 'rgb(138, 10, 18)'}} className='rounded-tr-lg rounded-br-lg float-right px-2 py-1'>lk</button>

            <button style={{backgroundColor: 'black', color: 'white'}} className=' rounded-tl-lg rounded-bl-md  float-right px-2 py-1'>klsklslk</button>
 * 
 * 
 * <div ref={(el) => (scrollRevealRef.current[2] = el)}>
              <NewsletterForm
                className="m-0 mt-8 max-w-md md:flex"
                submitBtn="más información"
                onSubmit={onNewsletterSubmit}
              />
            </div>
 */}
            
          </div>

          {!!illustration && (
            <div className="relative -ml-6 -mr-6 py-10 lg:p-0">
              {illustration}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
