import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './routes/home'
import { es, fr } from './data/data';
import MbEs from './components/images/mbcloud.svg'
import MbFr from './components/images/mbcloud_fr.svg'

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home content={es.content} content2={es.content2} illustration={MbEs} footer={es.footer}/>}/>
      </Routes>
      <Routes>
        <Route path='/fr' element={<Home content={fr.content} content2={fr.content2} illustration={MbFr} footer={fr.footer}/>}/>
      </Routes>
    </BrowserRouter>
    
  )
}
