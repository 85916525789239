export const es = {
    content: 'Plataforma diseñada por GRUPO MEXBRIDGE para abrir un portal de comunicación con nuestros socios inversionistas y brindar un servicio personalizado en la gestión de la información sobre sus inversiones.',
    content2: 'Actualmente estamos trabajando para implementar cambios estructurales. Si desea más información, escriba a:',
    footer: 'Todos los derechos reservados'
}

export const fr = {
    content: "Plateforme conçue par GRUPO MEXBRIDGE afin d'ouvrir un espace de communication avec nos partenaires-investisseurs et leur fournir un service personnalisé dans la gestion des informations relatives à leurs investissements.",
    content2: "Nous travaillons actuellement à la mise en œuvre de changements structurels. Pour plus d'informations, veuillez écrire à:",
    footer: 'Tous droits réservés'
}