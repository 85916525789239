import React from 'react'
import { Layout } from '../components/layout'
import { Hero } from '../components/hero'
import { HeroIllustration } from '../components/hero-illustration'
export default function HomePage({content, content2, illustration, footer}) {
  return (
    <Layout footer={footer}>
      <Hero
        title="MexBridge Cloud"
        content={content}
        content2 ={content2}
        email= "inversiones@grupomexbridge.com"
        illustration={<HeroIllustration illustration={illustration}/>}
      />
    </Layout>
  )
}
