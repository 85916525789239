import React from 'react'
// import { Link } from 'react-router-dom'

import { Logo } from './logo'

export function Header({ title = '' }) {
  return (
    <header className="relative py-6">
      <div className="mx-auto w-full max-w-6xl px-6">
        <div className="relative flex items-center justify-between float-right">
          <h1 className="m-0 text-xl font-bold uppercase leading-none">
            <a href="https://www.grupomexbridge.com" className="flex items-center no-underline">
            <Logo className="mr-2"  /> {title}
            </a>
            
          </h1>
        </div>
      </div>
    </header>
  )
}
